import { NavItem, TabNav } from "@mobiscroll/react";
import { Select } from "@mobiscroll/react5";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { Fragment, useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Loader from "src/components/Dashboard/common/Loader";
import allInvoice from "../../../assets/all-invoice.svg";
import UserTwo from "../../../assets/icons/UserTwo.png";
import defultPatientIcon from "../../../assets/icons/defultPatientIcon.svg";
import ActionCellRender from "../Layouts/ActionCellRender";
import "../Layouts/Appointments.scss";
import "./Invoice.scss";
import InvoiceDetail from "./InvoiceDetail";
import { listInvoices } from "./api";
import DownloadCellRenderer from "./DownloadCellRenderer";

//redux store for invoices
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvoices } from "src/features/invoices/invoicesActions";


const PatientNameCellRenderer = (params) => {
  return (
    <>
      <Link
        className="docg-app-patient-name align-items-start w-100"
        to={`invoice-details/${params.value.invoiceId}`}
      >
        <Image
          src={
            params.value.patientProfilePicture
              ? params.value.patientProfilePicture
              : defultPatientIcon
          }
          alt="Images"
          roundedCircle
          style={{ width: 35, height: 35 }}
        />{" "}
        {params.value.patientName}
      </Link>
    </>
  );
};

function Invoice(props) {
  const onQuickFilterChanged = () => {
    gridApi.setQuickFilter(document.getElementById("quickFilter").value);
  };

  // const history = useHistory();

  const [displayRecords, setDisplayRecords] = useState("00");
  const [totalRecords, setTotalRecords] = useState(0);
  const [loader, setLoader] = useState(true);
  const [rowData, setRowData] = useState([]);
  const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [activeTab, setActiveTab] = useState("allinvoice");
  const [data, setData] = useState(["00"]);

  const dispatch = useDispatch();
  const invoiceList = useSelector(state => state.invoices?.invoiceList || {})

  React.useEffect(() => {
    if (invoiceList?.data?.length > 0) {
      listAllInvoices(invoiceList);
    }
  }, [invoiceList]);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      await dispatch(fetchInvoices(displayRecords));
      setLoader(false);
    };
    fetchData();
  }, [dispatch, displayRecords]);

  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {};

  const listAllInvoices = async (res) => {
    setLoader(true);

    try {
      setRowData(res?.data);
      let value = res?.data.length < 10 ? `0${res?.data.length}` : res.data.length;

      if (res.data.length > 0) {
        setData((prevProps) => {
          let arr = [];
          for (
            let index = 0;
            index <= Math.floor(res.totalCount / 5);
            index++
          ) {
            let i = index * 5;
            let value = i < 10 ? `0${i}` : i;
            arr.push(value);
          }
          if (res.totalCount < arr[arr.length - 1] || res.totalCount < 5) {
            let value =
              res.totalCount < 10 ? `0${res.totalCount}` : res.totalCount;
            arr.push(value);
          }
          return arr;
        });
        setDisplayRecords(value);
        setTotalRecords(res.totalCount);
      }
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onPageSizeChanged = async (e) => {
    if (e.valueText != displayRecords) {
      dispatch(fetchInvoices(e.valueText));
    }
    setDisplayRecords(e.valueText);
  };

  const history = useHistory();

  const AmountRenderer = (params) => {
    let amount = +params?.data?.amount;
    return <span>&#8377; {amount?.toLocaleString("en-IN")}</span>;
  };

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 100,
  };

  return activeTab === "allinvoice" ? (
    <Fragment>
      <Loader show={loader} />
      {/* Main Content start Here  */}
      <Card className="docg-card docg-appointment docg-invoice patient-wrapper card-wrapper mt-0 mb-0">
        <CardHeader className="docg-card-header pb-0 customHeader d-flex justify-content-between align-items-center">
          <TabNav className="docg-tabs" theme="windows" context="#tab-content">
            <NavItem
              selected={activeTab === "allinvoice"}
              onClick={() => {
                history.push("/invoice");
                setActiveTab("allinvoice");
              }}
            >
              <img src={allInvoice} alt="" />
              All Invoices
            </NavItem>
          </TabNav>
        </CardHeader>

        <CardBody className="docg-card-body p-0">
          <Row className="invoice-data">
            <Col>
              <div
                className="ag-theme-alpine custom-ag"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <div className="records-display-new">
                  <span>Showing&nbsp;</span>
                  <label className="record-selection-dropdown" htmlFor="">
                    <Select
                      touchUi={false}
                      onChange={onPageSizeChanged}
                      value={displayRecords}
                      data={data}
                    />
                  </label>
                  <span>&nbsp;Records</span>
                </div>
                <AgGridReact
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    actionCellRender: ActionCellRender,
                    patientNameCellRenderer: PatientNameCellRenderer,
                    amountRenderer: AmountRenderer,
                    downloadCellRender: DownloadCellRenderer
                  }}
                  valueFormatter={(params) => {
                    return {
                      patientId: params.data.patientId,
                      id: params.data.id,
                    };
                  }}
                  defaultColGroupDef={defaultColGroupDef}
                  enableRangeSelection={true}
                  pagination={true}
                  onGridReady={onGridReady}
                  paginationPageSize={10}
                  domLayout={"autoHeight"}
                  animateRows={true}
                >
                  <AgGridColumn
                    headerName="Invoice No."
                    headerTooltip="Invoice No."
                    field="uhid"
                    tooltipField="uhid"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Patient Name"
                    headerTooltip="Patient Name"
                    tooltipField="to"
                    field="to"
                    initialPinned="left"
                    initialSort="asc"
                    cellRenderer="patientNameCellRenderer"
                    className="align-items-start"
                    valueFormatter={(params) => params.data.to}
                    valueGetter={(params) => {
                      return {
                        patientName: params.data.to,
                        patientProfilePicture:
                          params.data.patientProfilePicture,
                        invoiceId: params.data.id,
                      };
                    }}
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Invoice Date"
                    headerTooltip="Invoice Date"
                    field="createdAt"
                    valueFormatter={(params) => {
                      return new Date(params.value).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        month: "short",
                        day: "numeric",
                      });
                    }}
                    tooltipValueGetter={(params) => {
                      return new Date(params.value).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        month: "short",
                        day: "numeric",
                      });
                    }}
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Bill Amount"
                    headerTooltip="Bill Amount"
                    field="amount"
                    tooltipField="amount"
                    cellRenderer="amountRenderer"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Status"
                    headerTooltip="Status"
                    field="status"
                    tooltipField="status"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Payment Mode"
                    headerTooltip="Payment Mode"
                    field="paymentMethod"
                    tooltipField="paymentMethod"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Reason"
                    headerTooltip="Reason"
                    field="reason"
                    tooltipField="reason"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Download/Preview"
                    headerTooltip="Download invoice"
                    field="id"
                    cellRenderer="downloadCellRender"
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  ) : (
    <InvoiceDetail />
  );
}

export default Invoice;
