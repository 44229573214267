import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    appointmentsList: [],
    lastFetch: null          // tracks the last fetch time
}

const appointmentsSlice = createSlice({
    name: 'appointments',
    initialState,
    reducers: {
        setAppointments: (state, action) => {
            state.appointmentsList = action.payload;
            state.lastFetch = Date.now();
        },
        resetAppointments: (state) => {
            state.appointmentsList = [];
            state.lastFetch = null;
        }
    }
})

export const { setAppointments, resetAppointments } = appointmentsSlice.actions;
export default appointmentsSlice.reducer;