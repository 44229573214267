import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    invoiceList: [],
    lastFetch: null
}

const invoicesSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
        setInvoices: (state, action) => {
            state.invoiceList = action.payload;
            state.lastFetch = Date.now()
        },
        resetInvoices: state => {
            state.invoiceList = [];
            state.lastFetch = null
        }
    }
})

export const { setInvoices, resetInvoices } = invoicesSlice.actions;
export default invoicesSlice.reducer;