import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import downloadIcon from "src/assets/download.svg";
import shareIcon from "src/assets/whatsapp-share.png";
import { useLocation } from "react-router-dom";
import { getUploadedFileLink, getUploadPolicyLink } from "../Fee/api";
import Loader from "src/components/Dashboard/common/Loader";
import axios from "axios";
import { base64StringtoFile } from "src/lib/ReusableUtils";
import mobiscroll, { snackbar, toast } from "@mobiscroll/react";

const PDFViewer = ({ link, title, show, setShow, fileName, customButton }) => {

  const [loader, setLoader] = useState(false);
  const path = useLocation().pathname;

  // save invoice pdf
  const savePdf = () => {
    const aLink = document.createElement("a");
    aLink.href = `${link}`;
    aLink.download = fileName || "test";
    aLink.click();
    aLink.remove();

  };

  // share invoice link on whatsapp
  const sharePdf = async () => {
    setLoader(true);
    try {
      toast({ message: "Wait a moment please..", color: "success", });

      const res = await getUploadPolicyLink({ fileName: fileName, });

      if (res !== null) {
        toast({ message: "Creating pdf", color: "success", });

        const newPdf = base64StringtoFile(link, fileName);
        const _res = await axios.put(res.url, newPdf, {
          headers: {
            "Content-Type": "application/pdf",
            "Access-Control-Allow-Origin": "*",
          },
        });

        if (_res.status == 200) {
          toast({ message: "Getting your file ready", color: "success", });
          const getFileLink = await getUploadedFileLink({ fileName: res.name })

          //share on whatsapp
          const message = `Here is your invoice : ${fileName} - ${getFileLink.url}`;
          const shareUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
          window.open(shareUrl, '_blank');
        }

      }

    } catch (error) {
      console.log(error);
      snackbar({
        message: error.message,
        color: "danger",
      });
    }
    setLoader(false);
  }


  return (
    <>
      <Loader show={loader} />
      <Modal
        onHide={() => setShow(false)}
        size="lg"
        show={show}
        centered
        // onHide={handleNotificationsView}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header
          className="align-items-center d-flex"
          closeButton={customButton ? false : true}
        >
          <Modal.Title id="example-modal-sizes-title-lg" className="ml-3">
            <span className="ModalTitle">{title}</span>
          </Modal.Title>
          <div className="w-100"></div>
          <img
            style={{
              maxHeight: "1.4rem",
              maxWidth: "1.4rem",
              height: "1.4rem",
              width: "auto",
              cursor: "pointer"
            }}
            onClick={sharePdf}
            src={shareIcon}
            className={path !== '/' ? "mr-4" : "d-none"}
            alt=""
            title="Share invoice"
          />
          <img
            style={{
              maxHeight: "1.25rem",
              maxWidth: "1.25rem",
              height: "1.25rem",
              width: "auto",
              cursor: "pointer"
            }}
            onClick={savePdf}
            src={downloadIcon}
            className="mr-3"
            alt="download"
            title="Download invoice"
          />
          {customButton}
        </Modal.Header>
        <Modal.Body
          className="d-flex justify-content-center align-items-center"
          style={{ maxHeight: "82vh", overflow: "auto" }}
        >
          {
            <iframe
              title="pdf"
              src={link}
              style={{ width: "600px", height: "500px" }}
              frameborder="0"
            ></iframe>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PDFViewer;
