import { snackbar } from "@mobiscroll/react5";
import { getQuery, postMutation, } from "src/components/ApolloConnection/ApolloProviderComponent";
import { CANCEL_APPOINTMENT } from "src/components/graphql/GraphQLmutation";
import { LIST_TODAY_APPOINTMENTS } from "src/components/graphql/GraphQLquery";

export async function listAppointments(take, start, end, filters) {
  try {
    const response = await getQuery(
      LIST_TODAY_APPOINTMENTS(take, start, end, filters)
    );
    if (typeof response == "undefined") {
      throw new Error("error", response);
    } else {
      if (response?.errors) {
        throw new Error("error", response);
      } else if (
        response?.data?.listAppointmentsDoctor &&
        response?.data?.listAppointmentsDoctor?.data
      ) {
        snackbar({
          message: "List of Appointments fetched successfully.",
          color: "success",
        });
        return response?.data?.listAppointmentsDoctor;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    // snackbar({
    //   message: "Some error occured. Try again",
    //   color: "danger",
    // });
    console.log("Error at Listing Appointments: ", e);
    return null;
  }
}

export async function cancelAppointment(data) {
  try {
    let response = await postMutation(CANCEL_APPOINTMENT, data);
    if (
      response.data !== null &&
      response?.data?.cancelAppointment?.statusCode >= 200 &&
      response?.data?.cancelAppointment?.statusCode < 300
    ) {
      snackbar({
        message: "Appointment Cancelled Successfully.",
        color: "success",
      });
      return true;
    } else {
      throw new Error(response?.data?.cancelAppointment?.message);
    }
  } catch (e) {
    snackbar({
      message: e.message || "Some error occured while cancelling Patient's Appointment. Try again later.",
      color: "danger",
    });
    return false;
  }
}
