import { isMobileOnly } from "react-device-detect";
import mobiscroll from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { Fragment, Suspense, useEffect, useState } from "react";
import { Button, Card, Image } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsInfoCircleFill } from "react-icons/bs";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "src/components/Dashboard/common/Loader";
import { subscribeListAppointmentBeep } from "src/components/graphql/GraphQLsubscription";
import useQuery from "src/lib/useQuery";
import { useStore } from "src/store/Store";
import Appointment from "../../../assets/icons/Appointment.svg";
import ActionCellRender from "./ActionCellRender";
import "./Appointments.scss";
import EndConsultationModal from "./EndConsultationModal";
import PatientNameCellRenderer from "./PatientNameCellRenderer";
//apii
import { Select } from "@mobiscroll/react5";
import { Modal } from "react-bootstrap";
import CardWrapper from "./CardWrapper";
import { generateClient } from "@aws-amplify/api";
import { subClient, subscription, } from "./../../ApolloConnection/ApolloProviderComponent";
import { Amplify } from "aws-amplify";

// using redux to store the appointments
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppointments } from "src/features/appointments/appointmentsActions";

mobiscroll.settings = {
  theme: "ios",
  themeVariant: "light",
};

const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

const AppointmentsCard = ({
  title = true,
  wrapInCard = true,
  setShow = () => { },
  paginationPageSize = 10,
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  let query = useQuery();
  const { dispatchKPI } = useStore();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [displayRecords, setDisplayRecords] = useState("00");
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [inProgressData, setInProgressData] = useState(null);
  const [data, setData] = useState(["00"]);
  const [loader, setLoader] = useState(false);
  const [openEndConsultation, setEndConsultation] = useState(false);

  const dispatch = useDispatch();
  const appointmentsList = useSelector((state) => state.appointments?.appointmentsList || []);

  useEffect(() => {
    dispatch(fetchAppointments(displayRecords));
  }, [dispatch, displayRecords]);

  const listTodayAppointments = React.useCallback(
    (res) => {
      setLoader(true);

      try {

        let withRed = res?.filter((item) => item.patientStatus == "Red");
        let withoutRed = res?.filter((item) => item.patientStatus !== "Red");

        setRowData(withoutRed.concat(withRed));

        let value = res?.length < 10 ? `0${res?.length}` : res.length;
        dispatchKPI({ type: "APPOINTMENTS", payload: res.length });

        if (res.length > 0)
          dispatchKPI({ type: "LAST_APPOINTMENT", payload: res[res.length - 1]?.id, });

        let flag = false;

        if (res.length > 0) {
          for (let index = 0; index < res.length; index++) {
            const appointment = res[index];
            dispatchKPI({ type: "NEXT_APPOINTMENT", payload: appointment.start, });
            flag = true;
            break;
          }
        }

        if (!flag)
          dispatchKPI({ type: "NEXT_APPOINTMENT", payload: null, });

        setData((prevProps) => {
          let arr = [];
          for (let index = 0; index <= res.totalCount; index++) {
            let value = index < 10 ? `0${index}` : index;
            arr.push(value);
          }
          return arr;
        });

        setDisplayRecords(value);
        setTotalRecords(res.totalCount);
        sessionStorage.setItem("Today's Appointment", res.totalCount);

      } catch (e) {
        console.log(e);
      }
      setLoader(false);
    },
    [setLoader, displayRecords, setRowData, fetchAppointments]
  );

  React.useEffect(() => {
    if (appointmentsList.length > 0) {
      listTodayAppointments(appointmentsList);
    }
  }, [appointmentsList]);

  React.useEffect(() => {
    let prompt = query.get("prompt");
    if (prompt == "true") {
      let _data = rowData.find((appt) => appt.status == "In-Progress");
      setInProgressData(_data);
      if (_data) {
        setEndConsultation(true);
      } else {
        setEndConsultation(false);
      }
      searchParams.delete("prompt");
    }
  }, [rowData]);

  //subscribe for beep to check for changes in appointments from backend - if yes than fetch fresh data
  const subscribeAppointments = () => {
    const doctorId = sessionStorage.getItem("role-id");

    subscription(subscribeListAppointmentBeep(doctorId), {}, async (response) => {

      if (
        !(
          response.data.subscribeListAppointmentBeep.statusCode >= 200 &&
          response.data.subscribeListAppointmentBeep.statusCode < 300
        ) ||
        response.data.subscribeListAppointmentBeep.errors != null
      ) {
        return;
      }
      setLoader(true);

      try {
        dispatch(fetchAppointments(displayRecords, true))
      } catch (err) {
        console.log(err);
      }

      setLoader(false);
    },
      (error) => {
        console.log("Subscription error:", error);
      }
    );
  };

  React.useEffect(() => {
    subscribeAppointments();
  }, [displayRecords]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onQuickFilterChanged = () => {
    gridApi.setQuickFilter(document.getElementById("quickFilter").value);
  };

  const onPageSizeChanged = async (e) => {
    setDisplayRecords(e.valueText);
  };

  const onCloseEndConsultation = React.useCallback(() => {
    setEndConsultation(false);
  }, []);

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 100,
    suppressMovable: true,
  };

  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {};

  const routeChange = () => {
    history.push("/calendar");
  };

  return (
    <Fragment>
      <Loader show={loader} />
      {openEndConsultation && (
        <EndConsultationModal
          data={inProgressData}
          show={openEndConsultation}
          onClose={onCloseEndConsultation}
        />
      )}
      <CardWrapper
        cardClass="docg-card"
        cardBodyClass="docg-card-body p-0"
        headerComponent={
          title && (
            <CardHeader
              onClick={() => setShow(true)}
              className="customCardHeader d-flex justify-content-between"
            >
              <CardTitle className="customCardTitle">
                <Image src={Appointment} alt="" className="mr-2" />{" "}
                Appointments&nbsp;
                <OverlayTrigger
                  key="bottom"
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-bottom">
                      <p className="d-flex align-items-center justify-content-start">
                        <span
                          style={{
                            background: "#dc3545",
                            width: "10px",
                            height: "20px",
                          }}
                        >
                          &nbsp; &nbsp;
                        </span>
                        &nbsp; - Late
                      </p>
                      <p className="d-flex align-items-center justify-content-start">
                        <span
                          style={{
                            background: "#1aab73",
                            width: "10px",
                            height: "20px",
                          }}
                        >
                          &nbsp;
                        </span>
                        &nbsp; - Ontime
                      </p>
                      <p className="d-flex align-items-center justify-content-start">
                        <span
                          style={{
                            background: "#ffc107",
                            width: "10px",
                            height: "20px",
                          }}
                        >
                          &nbsp; &nbsp;
                        </span>
                        &nbsp; - Checked in
                      </p>
                      <p className="d-flex align-items-center justify-content-start">
                        <span
                          style={{
                            background: "#6c757d",
                            width: "10px",
                            height: "20px",
                          }}
                        >
                          &nbsp; &nbsp;
                        </span>
                        &nbsp; - No status
                      </p>
                    </Tooltip>
                  }
                >
                  <BsInfoCircleFill />
                </OverlayTrigger>
              </CardTitle>
              {!isMobileOnly && (
                <>
                  <br />
                  <div className="d-flex align-items-center">
                    <div className="docg-table-search">
                      <input
                        type="text"
                        onInput={() => onQuickFilterChanged()}
                        id="quickFilter"
                        placeholder="Search..."
                      />
                    </div>
                    <Button
                      className="theme-button btn-xs"
                      onClick={routeChange}
                    >
                      <span className="material-icons pr-1">
                        add_circle_outline
                      </span>{" "}
                      Add
                    </Button>
                  </div>
                </>
              )}
            </CardHeader>
          )
        }
        wrap={wrapInCard}
      >
        {" "}
        {!isMobileOnly && (
          <div className="records-display-new">
            <span>Showing&nbsp;</span>
            <label className="record-selection-dropdown" htmlFor="">
              <Select
                touchUi={false}
                onChange={onPageSizeChanged}
                value={displayRecords}
                data={data}
              />
            </label>
            <span>&nbsp;Records</span>
          </div>
        )}
        <div
          className="ag-theme-alpine"
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <AgGridReact
            rowData={rowData}
            defaultColDef={defaultColDef}
            frameworkComponents={{
              actionCellRender: ActionCellRender,
              patientCellRenderer: PatientNameCellRenderer,
            }}
            defaultColGroupDef={defaultColGroupDef}
            enableRangeSelection={true}
            pagination={true}
            paginationPageSize={paginationPageSize}
            onGridReady={onGridReady}
            domLayout={"autoHeight"}
            animateRows={true}
          >
            <AgGridColumn
              headerName="Patient Name"
              headerTooltip="Patient Name"
              field="patient"
              // initialPinned="left"
              initialSort="asc"
              cellRenderer="patientCellRenderer"
              minWidth={300}
              valueGetter={(params) => {
                return {
                  appointmentId: params.data.id,
                  patientDetails: params.data.patient,
                };
              }}
              tooltipValueGetter={(params) => {
                return (
                  params.data.patient.firstName +
                  " " +
                  params.data.patient.lastName
                );
              }}
            ></AgGridColumn>
            <AgGridColumn
              headerName="Time"
              headerTooltip="Time"
              field="start"
              valueFormatter={(params) => {
                return new Date(params.value).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                });
              }}
              tooltipValueGetter={(params) => {
                return new Date(params.value).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                });
              }}
            ></AgGridColumn>
            <AgGridColumn
              headerName="P. Type"
              headerTooltip="Patient Type"
              field="newPatient"
              valueFormatter={(params) => (params.value ? "New" : "Repeat")}
              tooltipValueGetter={(params) => (params.value ? "New" : "Repeat")}
            ></AgGridColumn>
            <AgGridColumn
              headerName="Payment Mode"
              headerTooltip="Payment Mode"
              field="paymentMode"
              tooltipField="paymentMode"
            ></AgGridColumn>
            <AgGridColumn
              headerName="Consl. Type"
              headerTooltip="Consultation Type"
              field="consultationType"
              valueFormatter={(params) => {
                return params?.value === "Face2Face"
                  ? "In person at clinic"
                  : params?.value;
              }}
              tooltipValueGetter={(params) => {
                return params?.value === "Face2Face"
                  ? "In person at clinic"
                  : params?.value;
              }}
            ></AgGridColumn>
            <AgGridColumn
              headerName=""
              field="actions"
              cellRenderer="actionCellRender"
              valueGetter={(params) => {
                return {
                  appointmentId: params.data.id,
                  patientDetails: params.data.patient,
                };
              }}
              filter={false}
              sortable={false}
            ></AgGridColumn>
          </AgGridReact>
        </div>
      </CardWrapper>
    </Fragment>
  );
};

const AppointmentsPopup = ({ show, setShow }) => {
  return (
    <Modal
      show={show}
      onHide={setShow}
      dialogClassName="history-dialog-modal"
      aria-labelledby="example-custom-modal-styling-title"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="ModalTitle">Appointments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Suspense fallback={<div>Loading...</div>}>
          <AppointmentsCard
            wrapInCard={false}
            title={false}
            paginationPageSize={20}
          />
        </Suspense>
      </Modal.Body>
    </Modal>
  );
};

const Appointments = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      {isMobileOnly && <AppointmentsPopup show={show} setShow={setShow} />}
      <AppointmentsCard show={show} setShow={setShow} />
    </>
  );
};


export default Appointments;