import { configureStore } from '@reduxjs/toolkit';
import appointmentReducer from '../features/appointments/appointmentsSlice';
import todoReducer from '../features/todos/todosSlice';
import patientReducer from '../features/patients/patientsSlice';
import invoiceReducer from '../features/invoices/invoicesSlice'

export const store = configureStore({
    reducer: {
        appointments: appointmentReducer,
        todos: todoReducer,
        patients: patientReducer,
        invoices: invoiceReducer
    }
})