import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    todoList: [],
    lastFetch: null
}

const todosSlice = createSlice({
    name: 'todos',
    initialState,
    reducers: {
        setTodos: (state, action) => {
            state.todoList = action.payload;
            state.lastFetch = Date.now()
        },
        resetTodos: state => {
            state.todoList = [];
            state.lastFetch = null
        }
    }
})

export const { setTodos, resetTodos } = todosSlice.actions;
export default todosSlice.reducer;