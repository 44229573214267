import { isMobileOnly } from 'react-device-detect';
import { setAppointments } from './appointmentsSlice';
import { listAppointments } from './appointments';

export const fetchAppointments = (displayRecords, forceRefresh = false) => async (dispatch, getState) => {
    let paginationPageSize = 10;

    const { appointmentsList, lastFetch } = getState().appointments;

    if (!forceRefresh && (Date.now() - lastFetch) < 60 * 60 * 1000) {
        return;
    }

    // if (!forceRefresh) return;

    try {
        const t_start = new Date(new Date().setHours(0, 0, 0, 0));
        const t_end = new Date(t_start.getTime() + 24 * 60 * 60 * 1000);
        let _displayRecords = (displayRecords <= 10) ? 10 : displayRecords;

        const res = await listAppointments(
            isMobileOnly ? paginationPageSize : +_displayRecords,
            t_start.toISOString(),
            t_end.toISOString(),
            "status=Booked,In-Progress"
        );


        if (res?.data) {
            dispatch(setAppointments(res.data)); // Store fetched data in Redux
        } else {
            console.error("No appointments data returned.");
        }

    } catch (error) {
        console.error("Error fetching appointments:", error);
    }
}