import React, { Fragment } from "react";
import "./Reporting.css";
import KPICard from "../Layouts/Card";
import AppointmentsYear from "./chart/AppointmentsYear";
import PatientsYear from "./chart/PatientsYear";
import TotalRevenue from "./chart/TotalRevenue";
import Reviews from "./chart/Reviews";
import { Row, Col } from "react-bootstrap";

import BillingIcon from "src/assets/icons/Billing.svg";
import TotalPaitent from "src/assets/icons/TotalPatient.svg";
import CalanderDarkIcon from "src/assets/icons/CalenderDarkIcon.svg";
import Datepicker from "@mobiscroll/react5";
import PendingTask from "src/assets/icons/PendingTask.svg";
import {
  getTotalBillingsDoctor,
  getTotalBillingsGrowthOfDoctor,
  getNumberOfPatients,
  getNumberOfPatientsGrowth,
  listDoctorReviews,
} from "../Layouts/api/kpiCards";

import { getTotalNumberOfAppointments } from "./api";

const tableArr = [
  {
    icon: TotalPaitent,
    title: "Total Patients",
    Circular: "proggressBar pateintsPer",
    percentage: 80,
    growthSince: "Since May 2021",
    footer: null,
    showRupees: false,
    api: getNumberOfPatients,
    growthApi: getNumberOfPatientsGrowth,
  },
  {
    icon: CalanderDarkIcon,
    title: "Total Appointments",
    Circular: "proggressBar",
    percentage: 100,
    footer: "Appointments",
    showRupees: false,
    api: getTotalNumberOfAppointments,
  },
  {
    icon: BillingIcon,
    title: "Total Revenue",
    Circular: "proggressBar",
    percentage: 100,
    growthSince: "Since May 2021",
    footer: null,
    api: getTotalBillingsDoctor,
    showRupees: true,
    growthApi: getTotalBillingsGrowthOfDoctor,
  },

  {
    icon: PendingTask,
    title: "Total Reviews",
    Circular: "proggressBar PendingTask",
    percentage: 100,
    showRupees: false,
    api: listDoctorReviews,
    footer: "Reviews",
  },
];

const Reporting = () => {
  return (
    <Fragment>
      <div className="mbsc-col-14 docg-dashboard docg-vh-standard d-flex flex-column">
        <div className="mbsc-row">
          <div className="d-flex mbsc-col-14">
            <KPICard cards={tableArr} />
          </div>
        </div>

        <div className="reports-container">

          <div className="reports-card">
            <div className="reporting-div bg-white">
              <AppointmentsYear divId={"AppointmentsYear"} check={true} />
            </div>
          </div>

          <div className="reports-card">
            <div className="reporting-div bg-white">
              <PatientsYear divId={"PatientsYear"} check={true} />
            </div>
          </div>


          <div className="reports-card">
            <div className="reporting-div bg-white">
              <TotalRevenue divId={"TotalRevenue"} check={true} />
            </div>
          </div>

          <div className="reports-card">
            <div className="reporting-div bg-white">
              <Reviews divId={"Reviews"} check={true} />
            </div>
          </div>

        </div>

        <div className="w-100">
        </div>
      </div>
    </Fragment>
  );
};
export default Reporting;
