import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    patientList: [],
    lastFetch: null
}

const patientsSlice = createSlice({
    name: 'patients',
    initialState,
    reducers: {
        setPatients: (state, action) => {
            state.patientList = action.payload;
            state.lastFetch = Date.now()
        },
        resetPatients: state => {
            state.patientList = [];
            state.lastFetch = null;
        }
    }
})

export const { setPatients, resetPatients } = patientsSlice.actions;
export default patientsSlice.reducer;