import { listPatients } from "src/components/Dashboard/PatientList/api";
import { setPatients } from "./patientsSlice";

export const fetchPatients = (displayRecords, forceRefresh = false) => async (dispatch, getState) => {
    const { patientList, lastFetch } = getState().patients;

    if (!forceRefresh && (Date.now() - lastFetch < (60 * 60 * 1000))) return;

    try {
        const response = await listPatients(displayRecords, '');

        if (response) {
            dispatch(setPatients(response));
        } else {
            console.log("No patients data returned");
        }

    } catch (error) {
        console.error("Error fetching patients: ", error);
    }
}