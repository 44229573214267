import { listInvoices } from "src/components/Dashboard/Invoice/api";
import { setInvoices } from "./invoicesSlice";

export const fetchInvoices = (displayRecords) => async (dispatch, getState) => {
    const { invoiceList, lastFetch } = getState().invoices;

    if (invoiceList?.data?.length > 0 && (Date.now() - lastFetch) < 60 * 60 * 1000) {
        return;
    }

    try {
        const doctorId = sessionStorage.getItem("role-id");
        let _displayRecords = (displayRecords <= 10) ? 10 : displayRecords;

        const response = await listInvoices(Number(_displayRecords), `doctorId=${doctorId}`);

        if (response) {
            dispatch(setInvoices(response))
        } else {
            console.log("No invoices data returned");
        }

    } catch (error) {
        console.log("Error fetching invoices: ", error);
    }
}