import { getTodoList } from "./api";
import { setTodos } from "./todosSlice";


export const fetchTodos = (displayRecords, forceRefresh = false) => async (dispatch, getState) => {
    const { todoList, lastFetch } = getState().todos;

    if (!forceRefresh && (Date.now() - lastFetch) < (60 * 60 * 1000)) return;

    try {
        let _displayRecords = (displayRecords <= 3) ? 3 : displayRecords;

        const response = await getTodoList(_displayRecords);

        if (response) {
            dispatch(setTodos(response));
        } else {
            console.log("No todos data returned");
        }

    } catch (error) {
        console.error("Error fetching todos:", error);
    }
}